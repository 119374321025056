import React, { useEffect, useState } from "react";
import DashHeader from "../DashHeader/DashHeader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../Utils/API";
import { toast } from "react-toastify";
// import { loadWeb3 } from "../../Utils/contract";
import { updateAuth, updateStatus } from "../../Redux/AuthSlice";
import { Spinner } from "react-bootstrap";

const Activation = () => {
  const jwt = useSelector((state) => state.Auth.jwtToken);
  const user = useSelector((state) => state.Auth.userId);
  const sec = useSelector((state) => state.Auth.jwtToken);
  const ip = useSelector((state) => state.Auth.ipAddress);

  let [loading, setloading] = useState(false);
  let [Amount, setAmount] = useState(0);
  let [uid, setuid] = useState(0);

  const [Net_Admin_Fund, setNet_Admin_Fund] = useState(0);
  const [Top_up, setTop_up] = useState(0);
  const [Name, setName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let URL = window.location.origin;

  // Loader component
  const Loader = () => (
    <div className="loader text-center">
      <Spinner animation="border" variant="primary" />
      <p>Loading...</p>
    </div>
  );

  const Activation_API = async () => {
    try {
      let responce = await API?.get(`userDetails?uid=${user}`, {
        headers: {
          Authorization: `${sec}`,
          "Content-Type": "application/json",
        },
      });
      responce = responce.data.data[0][0];

      console.log("Dash res-->", responce);

      setNet_Admin_Fund(responce.Net_Admin_Fund);
      setTop_up(responce.top_up);
      console.log(
        "activationcccccccghfghgh",
        responce.top_up,
        responce.Net_Admin_Fund
      );
    } catch (e) {
      console.log("Error While calling dashboard API", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const Activate = async (e) => {
    setloading(true);
    if (Amount !== 0) {
      if (parseFloat(Net_Admin_Fund) < parseFloat(Amount)) {
        toast.error("Insufficient Fund Balance");
        setloading(false);
      } else {
        try {
          let res = await API.post(
            "activation_Fund",
            {
              uid: uid,
              referby: user,
              amount: Amount,
              Amount80: 0,
              Amount20: 0,
              txn: "Fund Activation",
              actype: "Fund Activation",
            },
            {
              headers: {
                Authorization: `${jwt}`,
                "Content-Type": "application/json", // Adjust the content type as needed
              },
            }
          );
          console.log("activationccccccc", res);
          // toast.success("Transaction Successful");
          if (res.data.data == "successfully activate!!") {
            toast.success("successfully activate!!");
            navigate("/dashboard");
          } else {
            toast.error(res.data.data);
            setloading(false);
          }
        } catch (err) {
          console.log("error while calling function sell", err);
          if (
            err.response &&
            err.response.data &&
            err.response.data.showableMessage ===
              "Please login to access this resource"
          ) {
            dispatch(
              updateAuth({
                isAuth: false,
                userId: null,
                jwtToken: null,
                ipAddress: null,
              })
            );
            navigate("/");
          } else {
            toast.error("Transaction failed: " + (err.message || err));
          }
        }
      }
    } else {
      toast.error("Please Enter Amount First");
      setloading(false);
    }
  };

  const getUserNamebyuiId = async (e) => {
    const newValue = e.target.value.replace(/[^0-9]/gi, "");
    setuid(newValue);

    // let newValue = e.target.value;
    //const newValue = value.replace(/[^A-Z a-z]/g, "");
    if (newValue === "") {
      setuid("");
      setName("");
    } else {
      setuid(newValue);

      let res = await API.get(`/checkUserAddress?uid=${newValue}`);
      console.log("checkUserAddress", res.data.data);
      if (res.data.data === "") {
        setName("Wrong User Address");
      } else {
        // let { f_name } = res.data.data[0];
        setName(res.data.data);
      }
    }
  };
  const Amount_Data = (event) => {
    const newValue = event.target.value.replace(/[^0-9]/gi, "");
    setAmount(newValue);
  };

  useEffect(() => {
    Activation_API();
  }, []);
  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">Activate</div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">
                    Activate / Upgrade
                  </div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Activate
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3" />
            <div className="col-lg-6">
              <div className="modal-dialog">
                <div className="modal-content exchange_width">
                  <div className="modal-header exchange_hd w-100">
                    <h4 className="w-100 text-center pt-2">
                      Available Fund : {Net_Admin_Fund}
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default table-wrapper ng-pristine ng-valid ng-valid-maxlength mt-0">
                      <div className="panel-body">
                        <div className="row align-items-center justify-content-between">
                          <div className="col-sm-4 col-6">
                            <label>Enter User ID</label>
                          </div>

                          <div className="col-sm-8 col-6">
                            <input
                              type="text"
                              className="form-control mb-20 shadow-none"
                              // id="uid"
                              // value={uid}
                              // maxLength={10}
                              // onChange={(e) => setuid(e.target.value)}
                              value={uid}
                              onChange={(e) => {
                                getUserNamebyuiId(e);
                              }}
                              style={{ width: "94%" }}
                            />
                          </div>
                        </div>

                        <div className="row align-items-center justify-content-between">
                          <div className="col-sm-4 col-6">
                            <label>Wallet Address </label>
                          </div>

                          <div className="col-sm-8 col-6">
                            <input
                              type="text"
                              className={`form-control mb-20 shadow-none ${
                                Name === "valid" ? "color-green" : "color-red"
                              }`}
                              name="Name"
                              placeholder="Wallet Address"
                              readOnly="true"
                              value={Name}
                              disabled={true}
                              style={{ width: "94%" }}
                            />
                          </div>
                        </div>

                        <div className="row align-items-center justify-content-between">
                          <div className="col-sm-4 col-6">
                            <label>Enter Amount</label>
                          </div>
                          <div className="col-sm-8 col-6">
                            <input
                              type="text"
                              className="form-control mb-20 shadow-none"
                              id="amountInput"
                              value={Amount}
                              onChange={Amount_Data}
                              maxLength={10}
                              //onChange={(e) => setAmount(e.target.value)}
                              style={{ width: "94%" }}
                            />
                          </div>
                        </div>
                        <br></br>
                        <div className="row align-items-center justify-content-center mx-0">
                          <div className="col-sm-4 row align-items-center justify-content-center">
                            {loading == true ? (
                              <>
                                <Loader />
                              </>
                            ) : (
                              <>
                                <button
                                  className="btn btn-theme1 withdraw_btn w-100 py-sm-3 py-2 shadow-none"
                                  onClick={() => Activate()}
                                >
                                  Activate
                                </button>
                              </>
                            )}
                          </div>
                          <div className="col-12 ">
                            <p className="text-white fs-6 fw-bold text-center py-2 mb-0 notetext">
                              Note: Activate Amount Should Be Multiple of 50.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default Activation;
